.business-name {
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background: url(../public/images/bg-pattern.png),linear-gradient(270deg,#7b4397,#dc2430); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 10px 5px;
}
.top-bar-download-links {
  text-align: right;
}
.business-top-bar {
  color: #fff;
  background: url(../public/images/bg-pattern.png), #fbfafc;
}
.top-bar-logo {
  width: 10%;
}
.top-bar-logo img {
  width: 85%;
  background-color: #fff;
  border-radius: 50%;
}
.top-bar-download-links img {
  width: 200px;
  padding: 0 0 0 10px;
}
.businessDetail {
  margin-left: 320px;
}
.main-business-detail {
  margin-left: 0;
  padding-bottom: 20px;
  background: #afcaf5ba;
}
.sidebar-wrapper {
  width: 295px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  overflow-y: auto;
  background-color: #fff;
  bottom: 0;
  transition: left 0.5s ease-out;
}
.business-detail-row {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.business-logo {
  height: 385px;
  border: 1px solid #8080802e;
  border-radius: 15px;
  background: #fff;
  padding: 12px;
}
.business-cover-img {
  height: 385px;
  background: #fff;
  border: 1px solid #8080802e;
  border-radius: 15px;
  padding: 10px;
}
.logo-frame {
  width: 40%;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}
.logo-frame img {
  width: 100%;
}
.hide {
  display: none;
}
.photo-gallery-row {
  background-color: #fff;
  border-radius: 25px;
}
.photo-gallery-row h3 {
  padding-top: 20px;
}
.photo-gallery-img {
  width: 100%;
}
.gallery-img {
  width: 100%;
  padding: 0 10px;
  height: auto;
  padding-bottom: 36px;
}
.photo-gallery-scroll {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}
.business-data-row {
  padding: 20px 0;
}
.business-text-area {
  background-color: #fff;
  padding: 10px 8px;
  border: 2px solid #dfeafc;
  border-radius: 15px;
  margin-bottom: 20px;
}
.business-text-area h6 {
  margin-bottom: 20px;
  background-color: rgb(223 234 252);
  padding: 8px 5px;
  border-radius: 5px;
}
.business-cover-img img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.profile-name h4 {
  text-align: center;
  padding-top: 10px;
}
.profile-icons {
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
}
.profile-icons li {
  list-style: none;
}
.profile-icons li a {
  list-style: none;
  background-color: rgb(235, 239, 246);
  margin: 0 5px;
  padding: 10px;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  color: #253b70;
}

.profile-icons li a:hover {
  background-color: rgb(77, 91, 113);
  color: rgb(177, 203, 247);
}
.profile-icons li svg {
  font-size: 23px;
}
.profile-stats {
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-stats li {
  width: 100px;
  list-style: none;
  height: 85px;
  border-top: 1px solid rgb(208, 221, 243);
  border-right: 1px solid rgb(208, 221, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profile-stats li:last-child {
  border-right: none;
}
.status-bg {
  background-color: yellow;
  padding: 5px;
  border-radius: 5px;
}
.reviews-div {
  display: flex;
  align-items: flex-start;
  text-align: left;
  background-color: rgb(223 234 252);
  margin-bottom: 25px;
  padding: 25px;
  max-height: 229px;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.review-title {
  text-align: left;

  padding: 12px 0;
}
.reviews-div:before {
  content: "";
  position: absolute;
  top: 26px;
  right: 100%;
  border: 8px solid transparent;
  border-right: 8px solid #59667a;
}
.reviews-div:after {
  content: "";
  position: absolute;
  top: 26px;
  right: 100%;
  border: 8px solid transparent;
  border-right: 8px solid rgb(223 234 252);
}
.review-title h4 {
  margin-top: -17px;
}
.avatar {
  width: 33%;
  border-radius: 50%;
}
.avatar-main {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-height: 186px;
  margin-bottom: 25px;
}
.dumy-profile {
  width: 100%;
}
span.MuiRating-root.MuiRating-sizeMedium.css-iljtu2-MuiRating-root {
  padding-top: 12px;
}

.business-detail-div {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
}
.business-div {
  /* margin-bottom: 30px; */
  min-height: 87px;
  padding: 15px 0;
}
.business-detail-div .business-div:last-child .paragraph {
  border-bottom: none;
}
.business-div .paragraph {
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.6;
  letter-spacing: 0.02em;
  font-size: 14px;
  border-bottom: 1px solid #e6edef;
  padding-bottom: 10px;
}
.business-opening {
  display: flex;
  padding-bottom: 1.4px;
}
.business-opening .opening-day {
  width: 78px;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags-div {
  margin-bottom: 10px;
  padding-right: 5px;
}
.map-container {
  position: relative;
  width: 100%;
  height: 67vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.map-row {
  position: absolute;
  width: 100%;
}
.business-div-map {
  height: 71vh;
}
.facilities {
  margin-right: 20px;
  border: 1px solid #c4d5f2;
  padding: 5px 15px;
  border-radius: 25px;
}

.table-striped tbody tr {
  cursor: pointer;
}

@media screen and (max-width: 767px) {

.top-bar-logo {
    width: 20%;
}
.top-bar-logo img {
  width: 100%;
}
.top-bar-download-links img {
  width: 44%;
  padding: 0 2px;
}
.business-logo {
  margin-bottom: 13px;
}
.business-cover-img img {
  object-fit: contain;
}
.business-cover-img {
  height: auto;
}
.business-detail-row {
  padding-bottom: 13px;
  padding-top: 13px;
}
li.nav-item {
  margin: 0 5px 8px 5px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
button#noanim-tab-example-tab-profile, button#noanim-tab-example-tab-reviews, button#noanim-tab-example-tab-contact, button#noanim-tab-example-tab-home {
  width: 100%;
  /* margin-bottom: 15px; */
}
.avatar-main {
  display: none;
}
.reviews-div {
  max-height: 300px;
}
.reviews-main-div h5 {
  padding-bottom: 20px;
}
.business-div-map {
  height: 20vh;
}
.business-logo {
  height: auto;
}
.sidebar-wrapper {
   width: 100%;
  /* height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  overflow-y: auto;
  background-color: #fff;
  bottom: 0;
  transition: left 0.5s ease-out;
  /* display: none; */
}
/* .sidebar-wrapper {
  display: none;
} */
.business-section {
  margin-left: 0;
  margin-right: 0;
}
.main-section {
  background: none;
  border: none;
}
.card {
  border: none !important;
}
.tabs-button-ul {
  flex-direction: row;
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  padding: 36px 0 5px 0 !important;
}
.business-detail-row.row {
  background: #afcaf5ba;
}
section.section.business-table {
  width: 100vh;
  background: #afcaf5ba;
}
.business-detail-div {
  border-radius: 15px;
  margin-bottom: 13px;
}
.photo-gallery-row.row {
  margin: 0;
}
ul.mb-3.tabs-button-ul.nav.nav-tabs {
  margin-bottom: 5px !important;
}
.card .card-body {
  padding: 5px !important;
  font-size: 12px;
}
.xyz {
  display: none;
}

}

img.business-images-grid {
  border-radius: 15px;
  padding: 6px 6px 6px 6px;
}
ul.tabs-button-ul.nav.nav-tabs {
  margin-bottom: 15px;
}
div#noanim-tab-example-tabpane-home {
  overflow: hidden;
  border-radius: 15px;
}