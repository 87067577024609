.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  padding: 28px 0;
  border-radius: 15px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #5e7aa8 !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
button#noanim-tab-example-tab-profile,
button#noanim-tab-example-tab-reviews,
button#noanim-tab-example-tab-contact,
button#noanim-tab-example-tab-home {
  background: #5e7aa830;
}
.tab-span {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab-span svg {
    margin-right: 8px;
}
li.nav-item {
    margin: 0 20px;
}
.nav-link {
    color: #5e7aa8 !important;
    border: none !important;
}